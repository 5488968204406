import _ from "lodash";
import { BSON } from "realm-web";
import Fuse from "fuse.js";
import {
  B_BLOCKED,
  B_INCOMING,
  B_RELEASED,
  Batch,
  BatchComparisonObject,
  BatchExtended,
  BatchFile,
  BatchPackage,
  BatchTimeline,
  PackageType,
} from "../model/batch.types";
import { Supplier, SupplierExtended } from "../model/supplier.types";
import { callFunction } from "../services/dbService";
import { shortenAlias, uploadAndGetBatchFileObject } from "./fileUtils";
import { D_COA } from "./commodityUtils";
import userService from "../services/userService";
import { doFuseSearch, getDocFromCollection } from "./baseUtils";
import {
  SO_CANCELED,
  SO_HANDLEDATWAREHOUSE,
  SO_T_BATCHCREATED,
  SupplierOrder,
  SupplierOrderExtended,
  SupplierOrderShipment,
  SupplierOrderTimelineEntry,
} from "../model/supplierOrder.types";
import { CustomerOrder, CustomerOrderTimelineEntry, UsedBatch, UsedBatchPackage } from "../model/customerOrder.types";
import { getAllListDifferences, getAllListDifferencesGeneral } from "./diffUtils";
import { SelectOption } from "../components/common/CustomSelect";
import { SortColumn } from "./filterUtils";
import { BASE_CURRENCY } from "./currencyUtils";
import { CustomerBatch } from "../model/customer/customerBatch.types";
import { getOrderNumber } from "./orderUtils";
import { DataContextInternalType } from "../context/dataContext";
import { isFinishedProduct } from "./finishedProductUtils";
import {
  Article,
  ArticleExtended,
  ArticleSnapshot,
  formatArticleUnit,
  getArticleSnapshot,
  InternalArticleExtended,
} from "./productArticleUtils";
import { CustomerContract } from "../model/customerContract.types";
import { extendSupplierOrder } from "./dataTransformationUtils";

// Function names
const UPSERTBATCH = "upsertBatch";
const UPDATEPACKAGES = "updateBatchPackages";
const INSERTBATCHANDUPDATESHIPMENT = "insertBatchAndUpdateShipment";
const BOOKUSEDBATCHES = "bookUsedBatches";

export const B_PACKAGE_DICT = {
  drums: "Drum",
  barrel: "Barrel",
  box: "Box",
  bags: "Bag",
  bottle: "Bottle",
  can: "Can",
  carton: "Carton",
  drumsOpen: "Drum (open)",
};

export const B_PACKAGE_DICT_PLURAL = {
  drums: "Drums",
  barrel: "Barrels",
  box: "Boxes",
  bags: "Bags",
  bottle: "Bottles",
  can: "Cans",
  carton: "Cartons",
  drumsOpen: "Drums (open)",
};

export const B_COMPARISON_KEYS_DICT = {
  lot: "LOT",
  stockedDate: "Stocked",
  expiry: "Expiration",
  packages: "Packages",
  notes: "Notes",
  supplier: "Supplier",
  customerOrders: "Related Orders",
  unit: "Unit",
  price: "Price",
  priceCurrency: "Currency",
  supplierCoA: "Supplier CoA",
  state: "State",
  ownCoA: "Rawbids CoA",
};

/**
 * Filter states
 */
export const B_STATE_OPTIONS: Array<SelectOption> = [
  { label: "Free", value: "free" },
  { label: "Incoming", value: "incoming" },
  { label: "Blocked", value: "blocked" },
  { label: "Released", value: "released" },
  { label: "Enabled", value: "enabled" },
  { label: "Disabled", value: "disabled" },
];

/**
 * Batch states
 */
export const B_STATES: Array<SelectOption> = [
  { label: _.upperFirst(B_INCOMING), value: B_INCOMING },
  { label: _.upperFirst(B_RELEASED), value: B_RELEASED },
  { label: _.upperFirst(B_BLOCKED), value: B_BLOCKED },
];

export const B_WAREHOUSE_OPTIONS = [
  { value: "main", label: "Main" },
  { value: "bio", label: "Bio" },
  { value: "outside", label: "Outside" },
];

export const B_LISTING_VIEWS = [
  { label: "Package View", value: "single" },
  { label: "Batch View", value: "grouped" },
];

export const BATCH_COMPARISON_KEYS: Array<keyof BatchComparisonObject> = [
  "lot",
  "stockedDate",
  "expiry",
  "packages",
  "notes",
  "supplier",
  "customerOrders",
  "unit",
  "price",
  "priceCurrency",
  "state",
  "supplierCoA",
  "ownCoA",
];

// timeline types
export const T_BATCHEDIT = "batchEdit";
export const T_BATCHPACKAGESEDIT = "batchPackagesEdit";
export const T_BATCHDISABLE = "batchDisable";
export const T_BATCHENABLE = "batchEnable";
export const T_BATCHCREATED = "batchCreated";
export const T_BATCHBOOKOUT = "batchBookOut";
export const T_BATCHBOOKIN = "batchBookIn";
export const T_BATCHRELEASED = "batchReleased";
export const T_BATCHBLOCKED = "batchBlocked";
export const T_BATCHCOACREATED = "coaCreated";

export interface CreationBatch
  extends Omit<
    Batch,
    "identifier" | "packages" | "supplierCoA" | "disabled" | "timeline" | "supplier" | "supplierOrder"
  > {
  packages: Array<CreationBatchPackage>;
  supplier: SelectOption<Supplier | SupplierExtended> | undefined;
  supplierOrder: SelectOption<SupplierOrderExtended> | undefined;
  supplierCoA: File | BatchFile | null;
}

export interface EditBatch
  extends Omit<
    Batch,
    "identifier" | "packages" | "disabled" | "timeline" | "supplier" | "supplierCoA" | "ownCoA" | "supplierOrder"
  > {
  packages: Array<BatchPackage>;
  supplier: SelectOption<Supplier> | undefined;
  supplierOrder: SupplierOrder | undefined;
  supplierCoA: BatchFile | null | File;
  ownCoA: BatchFile | null | File;
}

export interface CreationBatchPackage {
  _id: BSON.ObjectId;
  amountPackages: number;
  amountEach: number;
  packageType: "drums" | "bags" | "bottle" | "drumsOpen";
  warehouse: "main" | "outside" | "bio";
  warehouseLocation: string;
}

export interface ShipmentBatch extends Omit<Batch, "coa" | "supplierCoA" | "ownCoA"> {
  shipment: SupplierOrderShipment;
}

export interface ShipmentBatchExtended extends Omit<BatchExtended, "coa" | "supplierCoA" | "ownCoA"> {
  shipment: SupplierOrderShipment;
}

/**
 * Inserts a new batch into the database.
 * @param batch Batch that should be inserted into the database
 * @returns { Promise<Realm.Services.MongoDB.InsertOneResult<BSON.ObjectId> | false> } Result of the function
 */
export async function insertBatch(
  batch: Batch
): Promise<Realm.Services.MongoDB.InsertOneResult<BSON.ObjectId> | false> {
  return (await callUpsertBatch(batch, true)) as Realm.Services.MongoDB.InsertOneResult<BSON.ObjectId> | false;
}

/**
 * Updates an existing batch inside the database.
 * @param batch Batch that should be updated inside the database
 * @param batchId Optional id of commodity if it is not contained in commodity object
 * @returns { Promise<Realm.Services.MongoDB.UpdateResult<BSON.ObjectId> | false> } Result of the function
 */
export async function updateBatch(
  batch: Partial<Batch>,
  batchId?: BSON.ObjectId
): Promise<Realm.Services.MongoDB.UpdateResult<BSON.ObjectId> | false> {
  if (batchId) batch._id = batchId;
  return (await callUpsertBatch(batch, false)) as Realm.Services.MongoDB.UpdateResult<BSON.ObjectId> | false;
}

/**
 * Calls the upsert batch function in backend.
 * @param batch Batch that should be upsert
 * @param insert True for insert, else update
 * @returns { Promise<false | Realm.Services.MongoDB.InsertOneResult<BSON.ObjectId> | Realm.Services.MongoDB.UpdateResult<BSON.ObjectId>> } Result of the function
 */
async function callUpsertBatch(
  batch: Partial<Batch>,
  insert: boolean
): Promise<
  false | Realm.Services.MongoDB.InsertOneResult<BSON.ObjectId> | Realm.Services.MongoDB.UpdateResult<BSON.ObjectId>
> {
  return callFunction(UPSERTBATCH, [batch, insert]);
}

/**
 * Update single packages of a batch
 * @param batchId objectId of the batch containing the packages
 * @param packages list of updated packages
 * @param timelineEntry a timeline entry
 * @returns { Promise<boolean>} Result of the function
 */
export async function updateBatchPackages(
  batchId: BSON.ObjectId,
  packages: Array<BatchPackage>,
  timelineEntry: BatchTimeline
): Promise<boolean> {
  return await callFunction<boolean>(UPDATEPACKAGES, [batchId, packages, timelineEntry]);
}

/**
 * Inserts a new batch and updates the shipment it relates to.
 * @param batch Batch that should be added
 * @param supplierOrderId ID of the supplier order
 * @param shipmentId ID of the shipment
 * @param shipmentTimeline Timeline entry that should be added to the order
 * @returns { Promise<boolean> } Result of the function
 */
export async function insertBatchAndUpdateShipment(
  batch: Batch,
  supplierOrderId: BSON.ObjectId,
  shipmentId: BSON.ObjectId,
  shipmentTimeline: SupplierOrderTimelineEntry
): Promise<boolean> {
  return await callFunction<boolean>(INSERTBATCHANDUPDATESHIPMENT, [
    batch,
    supplierOrderId,
    shipmentId,
    shipmentTimeline,
  ]);
}

/**
 * Book out used batches and update customer orders
 * @param orderId the customer order id
 * @param usedBatches list of used batches
 * @param timelineEntry customer order timeline entry
 * @param batchTimelineEntries map with batch timeline entries for each used batch
 * @returns { Promise<boolean> } Result of the function
 */
export async function bookUsedBatches(
  orderId: string,
  usedBatches: Array<UsedBatch>,
  timelineEntry: CustomerOrderTimelineEntry,
  batchTimelineEntries: { [id: string]: BatchTimeline }
): Promise<boolean> {
  return await callFunction<boolean>(BOOKUSEDBATCHES, [orderId, usedBatches, timelineEntry, batchTimelineEntries]);
}

/**
 * Get the total amount of batch packages
 * @param batch the batch to get the total amount for
 * @returns {number} amount of all batch packages
 */
export function getBatchAmount(
  batch: Batch | ShipmentBatch | ShipmentBatchExtended | CustomerBatch | BatchExtended
): number {
  return batch.packages.reduce((a, b) => a + b.amountEach, 0);
}

/**
 * Get the used amount of used batch packages
 * @param usedBatch the batch to get the total amount for
 * @returns {number} amount of all used batch packages
 */
export function getUsedBatchAmount(usedBatch: UsedBatch): number {
  return usedBatch.packages.reduce((a, b) => a + b.amountUsed, 0);
}

/**
 * Get a default creation batch
 * @param article the commodity or finished product
 * @param supplierOrder supplier order from which the batch should be created
 * @returns {CreationBatch} a default batch used for batch creation
 */
export function getDefaultCreationBatch(
  article: InternalArticleExtended | ArticleSnapshot,
  supplierOrder?: SupplierOrderExtended
): CreationBatch {
  let firstValidSupplier, firstValidPrice;
  if (!supplierOrder) {
    firstValidSupplier = article.suppliers.find((s) => s.prices.length > 0);
    firstValidPrice =
      firstValidSupplier && firstValidSupplier.prices.find((p) => p.validUntil > new Date() && p.price > 0);
  }

  let soCOA;
  if (supplierOrder) {
    soCOA = supplierOrder.files.find((f) => f.type === D_COA);
  }

  const sup = supplierOrder ?? firstValidSupplier;
  return {
    _id: new BSON.ObjectId(),
    lot: "",
    stockedDate: new Date(),
    expiry: new Date(new Date().setFullYear(new Date().getFullYear() + 2)),
    commodity: getArticleSnapshot(article), // No need to filter for supplier or price here since it is not the final batch
    packages: [getDefaultCreationBatchPackage()],
    notes: "",
    supplier: sup
      ? {
          value: sup.supplier._id.toString(),
          label: sup.supplier.name,
          object: sup.supplier,
        }
      : undefined,
    customerOrders: supplierOrder ? supplierOrder.customerOrders.map((cO) => cO._id.toString()) : [],
    unit: supplierOrder
      ? supplierOrder.unit
      : article.unit
      ? article.unit
      : isFinishedProduct(article)
      ? "1000 pcs"
      : "kg",
    price: supplierOrder
      ? supplierOrder.priceCommodities / supplierOrder.amount
      : firstValidPrice
      ? firstValidPrice.price
      : 0,
    priceCurrency: supplierOrder ? supplierOrder.currency : firstValidPrice ? firstValidPrice.currency : BASE_CURRENCY,
    supplierCoA:
      soCOA && supplierOrder
        ? {
            _id: soCOA._id,
            name: shortenAlias(soCOA.path, ""),
            path: soCOA.path,
            date: soCOA.date,
            uploadedBy: supplierOrder.person._id.toString(),
          }
        : null,
    supplierOrder: supplierOrder
      ? { value: supplierOrder._id.toString(), label: getOrderNumber(supplierOrder), object: supplierOrder }
      : undefined,
    state: B_INCOMING,
    ownCoA: null,
  };
}

/**
 * Get a default creation batch package
 * @returns {CreationBatchPackage} default creation batch package
 */
export function getDefaultCreationBatchPackage(): CreationBatchPackage {
  return {
    _id: new BSON.ObjectId(),
    amountPackages: 1,
    amountEach: 25,
    packageType: "drums",
    warehouse: "main",
    warehouseLocation: "",
  };
}

/**
 * Get a default batch package
 * @param batch the batch document
 * @param originBatch the 'old' batch document to try to get a not already used identifier
 * @returns {BatchPackage} default creation batch package
 */
export function getDefaultBatchPackage(batch?: Batch | EditBatch, originBatch?: Batch): BatchPackage {
  let highestNumber = 0;
  if (batch) {
    const concatPackages = batch.packages.concat(originBatch?.packages || []);
    for (let i = 0; i < concatPackages.length; i++) {
      const p = concatPackages[i];
      if (p.number > highestNumber) highestNumber = p.number;
    }
  }
  return {
    _id: new BSON.ObjectId(),
    number: highestNumber + 1,
    amountEach: 25,
    packageType: "drums",
    warehouse: "main",
    warehouseLocation: "",
  };
}

/**
 * Resolve creation packages to actual batch packages
 * @param creationPackages list of creation batch packages
 * @returns {Array<BatchPackage>} list of converted batch packages
 */
export function convertCreationPackages(creationPackages: Array<CreationBatchPackage>): Array<BatchPackage> {
  const packages: Array<BatchPackage> = [];
  let counter = 1;
  for (let i = 0; i < creationPackages.length; i++) {
    const creationPackage = creationPackages[i];
    for (let j = 0; j < creationPackage.amountPackages; j++) {
      packages.push({
        _id: new BSON.ObjectId(),
        number: counter,
        amountEach: creationPackage.amountEach,
        packageType: creationPackage.packageType,
        warehouse: creationPackage.warehouse,
        warehouseLocation: creationPackage.warehouseLocation,
      });
      counter++;
    }
  }
  return packages;
}

/**
 * Get batch to be edited
 * @param batch the origin batch
 * @param context Data context - needed to resolve supplier
 * @param additionalBatches optional, additional batches/packages (only for package view)
 * @returns {EditBatch} a batch used for batch edit populated with given batch data
 */
export function getEditBatch(
  batch: Batch,
  context: DataContextInternalType,
  additionalBatches?: Array<Batch>
): EditBatch {
  let packages = batch.packages.slice();
  // Add additional packages but skip package from batch if already included
  if (additionalBatches)
    packages = packages.concat(
      additionalBatches.map((b) => b.packages[0]).filter((b) => batch.packages[0]._id.toString() !== b._id.toString())
    );

  const supplier = batch.supplier ? getDocFromCollection(context.supplier, batch.supplier) : undefined;
  const editBatch = {
    _id: batch._id,
    lot: batch.lot,
    state: batch.state,
    stockedDate: batch.stockedDate,
    expiry: batch.expiry,
    commodity: batch.commodity,
    packages: _.orderBy(packages, "number", "asc"),
    notes: batch.notes,
    supplier: supplier
      ? {
          value: batch.supplier,
          label: supplier.name,
          object: supplier,
        }
      : undefined,
    customerOrders: batch.customerOrders,
    unit: batch.unit,
    price: batch.price,
    priceCurrency: batch.priceCurrency,
    supplierCoA: batch.supplierCoA,
    ownCoA: batch.ownCoA,
  } as EditBatch;
  if (batch.supplierOrder) editBatch.supplierOrder = getDocFromCollection(context.supplierOrder, batch.supplierOrder);
  return editBatch;
}

/**
 * Convert an edit batch object to a batch object
 * @param editBatch the current edit batch
 * @param originBatch batch before edits
 * @returns {Batch} the converted batch
 */
export function getBatchFromEditBatch(editBatch: EditBatch, originBatch: Batch): Batch | false {
  if (!editBatch.supplier) return false;
  const batch = _.cloneDeep(editBatch) as unknown as Batch;
  let uploadedSupplierCoA;
  if (editBatch.supplierCoA instanceof File) {
    uploadedSupplierCoA = uploadAndGetBatchFileObject(
      editBatch.supplierCoA,
      editBatch.supplierCoA.name,
      editBatch.supplier?.object?._id.toString()
    );
    if (!uploadedSupplierCoA) return false;
  }
  let uploadedOwnCoA;
  if (editBatch.ownCoA instanceof File) {
    uploadedOwnCoA = uploadAndGetBatchFileObject(
      editBatch.ownCoA,
      editBatch.ownCoA.name,
      editBatch.supplier?.object?._id.toString()
    );
  }
  // We need either an upload or a string which means coa did not change and file is already available
  if (!uploadedSupplierCoA && !("_id" in batch.supplierCoA)) return false;
  batch.supplier = editBatch.supplier.value;
  batch.supplierCoA = uploadedSupplierCoA ?? (editBatch.supplierCoA as BatchFile);
  if (uploadedOwnCoA) batch.ownCoA = uploadedOwnCoA;

  batch.supplierOrder = editBatch.supplierOrder?._id.toString();

  // Add timeline entry
  batch.timeline = originBatch.timeline.concat(getBatchEditTimelineEntry(originBatch, batch));
  return batch;
}

/**
 * Get a batch edit timeline entry with differences between pre and post
 * @param pre the old batch document
 * @param post the new edited batch document
 * @returns {BatchTimeline} edit timeline entry
 */
export const getBatchEditTimelineEntry = (pre: Batch, post: Batch): BatchTimeline => {
  const preObject: BatchComparisonObject = {};
  const postObject: BatchComparisonObject = {};
  for (const key of BATCH_COMPARISON_KEYS) {
    // special handling for packages and customer orders
    if (["packages", "customerOrders"].includes(key)) continue;
    let preVal;
    let postVal;
    switch (key) {
      case "supplierCoA":
      case "ownCoA":
        // Only compare paths
        preVal = pre[key]?.path.toString();
        postVal = post[key]?.path.toString();
        break;
      default:
        preVal = pre[key];
        postVal = post[key];
        break;
    }
    if (!_.isEqual(preVal, postVal)) {
      _.set(preObject, key, preVal);
      _.set(postObject, key, postVal);
    }
  }
  // We only want to check ids, we don't care about differences in the orders content such as state
  const [differentOrdersPre, differentOrdersPost] = getAllListDifferencesGeneral(
    pre.customerOrders,
    post.customerOrders
  );
  if (differentOrdersPre.length > 0) {
    // Only save ids
    _.set(preObject, "customerOrders", differentOrdersPre);
  }
  if (differentOrdersPost.length > 0) {
    _.set(postObject, "customerOrders", differentOrdersPost);
  }
  // If no changes so far only packages changes, so return packages edit entry
  if (_.isEmpty(preObject) && _.isEmpty(postObject)) return getBatchPackagesEditTimelineEntry(pre, post);
  else {
    const [differentPackagesPre, differentPackagesPost] = getAllListDifferences(pre.packages, post.packages);
    if (differentPackagesPre.length > 0) _.set(preObject, "packages", differentPackagesPre);
    if (differentPackagesPost.length > 0) _.set(postObject, "packages", differentPackagesPost);
    return {
      _id: new BSON.ObjectId(),
      person: userService.getUserId(),
      date: new Date(),
      type: T_BATCHEDIT,
      pre: preObject,
      post: postObject,
    };
  }
};

/**
 * Get a batch edit timeline entry with differences between packages before and after
 * @param pre the old batch document
 * @param post the new edited batch document
 * @returns {BatchTimeline} timeline entry with edited packages
 */
export const getBatchPackagesEditTimelineEntry = (pre: Batch, post: Batch | EditBatch): BatchTimeline => {
  const [differentPre, differentPost] = getAllListDifferences(pre.packages, post.packages);
  const timelineEntry: BatchTimeline = {
    _id: new BSON.ObjectId(),
    person: userService.getUserId(),
    date: new Date(),
    type: T_BATCHPACKAGESEDIT,
  };
  if (differentPre.length > 0) timelineEntry.pre = { packages: differentPre };
  if (differentPost.length > 0) timelineEntry.post = { packages: differentPost };
  return timelineEntry;
};

/**
 * Get a timeline entry for used batches
 * @param batches list of selected batches
 * @param usedBatches list of used batches
 * @returns { {[id: string]: BatchTimeline} } Map with a timeline entry assigned for every used batch
 */
export const getBatchBookOutTimelineEntry = (
  batches: Array<Batch>,
  usedBatches: Array<UsedBatch>
): { [id: string]: BatchTimeline } => {
  const batchTimelineEntries: { [id: string]: BatchTimeline } = {};
  for (let i = 0; i < usedBatches.length; i++) {
    const usedBatch = usedBatches[i];
    const matchingBatch = batches.find((b) => b._id.toString() === usedBatch.batchId);
    if (!matchingBatch) continue;
    batchTimelineEntries[usedBatch.batchId] = getUsedBatchTimelineEntry(matchingBatch, usedBatch);
  }
  return batchTimelineEntries;
};

/**
 * Get a timeline entry for used batch
 * @param batch matching batch for used batch
 * @param usedBatch a used batch
 * @returns {BatchTimeline} batch timeline entry with diff
 */
export const getUsedBatchTimelineEntry = (batch: Batch, usedBatch: UsedBatch): BatchTimeline => {
  const originBatch = batch;
  const editBatch = _.cloneDeep(batch);
  for (let i = 0; i < usedBatch.packages.length; i++) {
    const pack = usedBatch.packages[i];
    const matchingPack = editBatch.packages.find((p) => p._id.toString() === pack.packageId);
    if (!matchingPack) continue;
    matchingPack.amountEach = matchingPack.amountEach - pack.amountUsed;
  }
  const [differentPre, differentPost] = getAllListDifferences(originBatch.packages, editBatch.packages);
  const timelineEntry = getBatchTimelineEntry(T_BATCHBOOKOUT);
  if (differentPre.length > 0) timelineEntry.pre = { packages: differentPre };
  if (differentPost.length > 0) timelineEntry.post = { packages: differentPost };
  return timelineEntry;
};

/**
 * Get a standard timeline entry without pre and post
 * @param type the timeline type
 * @param note optional, additional note
 * @returns {BatchTimeline} a batch timeline entry with the given type
 */
export const getBatchTimelineEntry = (type: string, note?: string): BatchTimeline => {
  const timelineEntry: BatchTimeline = {
    _id: new BSON.ObjectId(),
    person: userService.getUserId(),
    date: new Date(),
    type,
  };
  if (note) timelineEntry.note = note;
  return timelineEntry;
};

/**
 * Get the total amount of batch packages
 * @param packages optional, list of batch packages
 * @returns {number} amount of all batch packages
 */
export function getTimelinePackagesAmount(packages?: Array<BatchPackage>): number {
  if (!packages) return 0;
  return packages.reduce((a, b) => a + b.amountEach, 0);
}

/**
 * Get the difference in amount for a timeline entry
 * @param timelineEntry a batch timeline entry
 * @returns {number} the difference in amount
 */
export const getTimelineAmountDiff = (timelineEntry: BatchTimeline): number => {
  const preAmount = getTimelinePackagesAmount(timelineEntry.pre?.packages);
  const postAmount = getTimelinePackagesAmount(timelineEntry.post?.packages);
  return Math.round((preAmount - postAmount) * 100) / 100;
};

/**
 * Concat package info to a readable string
 * @param p a batch package
 * @param batch the batch
 * @returns {string} a string with package information
 */
export const concatPackageInfo = (p: BatchPackage, batch: Batch | BatchExtended): string => {
  return `Package #${p.number} - ${B_PACKAGE_DICT[p.packageType]} ${p.amountEach} ${formatArticleUnit(batch.unit)} - ${
    p.warehouseLocation
  } (${B_WAREHOUSE_OPTIONS.find((o) => o.value === batch.packages[0]?.warehouse)?.label ?? "Unknown"})`;
};

/**
 * Get default used batches for a list of batches
 * @param batches list of batches
 * @returns {Array<UsedBatch>} list of used batch objects
 */
export const getDefaultUsedBatches = (batches?: Array<Batch>): Array<UsedBatch> => {
  if (!batches || batches.length === 0) return [];
  return batches.map((b) => getDefaultUsedBatch(b));
};

/**
 * Get a default used batch for a batch
 * @param b a batch
 * @returns {UsedBatch} a used batch object
 */
export const getDefaultUsedBatch = (b: Batch): UsedBatch => {
  return {
    _id: new BSON.ObjectId(),
    batchId: b._id.toString(),
    identifier: b.identifier,
    supplierLot: b.lot,
    supplier: b.supplier,
    expiry: b.expiry,
    price: b.price,
    priceCurrency: b.priceCurrency,
    totalAmountUsed: 0,
    unit: b.unit,
    supplierCoA: b.supplierCoA,
    ownCoA: b.ownCoA,
    state: b.state,
    packages: [],
  };
};

/**
 * Get default used batch package
 * @param p a batch package
 * @param customAmount optional, custom used amount
 * @returns {UsedBatchPackage} transformed used batch package
 */
export const getSelectUsedBatchPackage = (p: BatchPackage, customAmount?: number): UsedBatchPackage => {
  return {
    _id: new BSON.ObjectId(),
    packageId: p._id.toString(),
    number: p.number,
    amountEach: p.amountEach,
    amountUsed: customAmount !== undefined ? customAmount : p.amountEach,
    packageType: p.packageType,
    warehouse: p.warehouse,
    warehouseLocation: p.warehouseLocation,
  } as UsedBatchPackage;
};

/**
 * Get prepared stock according to view, e.g. for package view a batch is prepared for every package
 * @param stock list of batches
 * @param view selected view
 * @param supplierOrders list of supplier order to be added to batch view
 * @returns {Array<Batch | ShipmentBatch>} list of prepared batches, i.e. single batch for every package in single view
 */
export const getPreparedStockForView = (
  stock: Array<Batch | ShipmentBatch>,
  view: SelectOption,
  supplierOrders?: Array<SupplierOrder>
): Array<Batch | ShipmentBatch> => {
  let preparedStock: Array<Batch | ShipmentBatch> = [];
  // For single view, just build a batch with package list of size 1
  if (view.value === "single") {
    for (let i = 0; i < stock.length; i++) {
      const batch = stock[i];
      for (let j = 0; j < batch.packages.length; j++) {
        const singleBatch = { ...batch };
        singleBatch.packages = [batch.packages[j]];
        preparedStock.push(singleBatch);
      }
    }
  } else {
    preparedStock = stock.slice();
  }

  // Add batch placeholder in grouped view
  if (supplierOrders && view.value === "grouped")
    for (let i = 0; i < supplierOrders.length; i++) {
      const sO = supplierOrders[i];
      if (sO.state !== SO_HANDLEDATWAREHOUSE) continue;
      const openShipments = sO.shipment.filter((s) => !s.timeline.some((t) => t.type === SO_T_BATCHCREATED));
      // Continue if no open shipments exists
      if (openShipments.length === 0) continue;
      for (let j = 0; j < openShipments.length; j++) {
        const openShipment = openShipments[j];
        preparedStock.push(getDefaultShipmentBatch(sO, openShipment));
      }
    }
  return preparedStock;
};

/**
 * Get a location description for a batch and packages
 * @param batch a batch object
 * @returns {string} location description for batch
 */
export const getStockLocation = (batch: Batch | ShipmentBatch): string => {
  if (batch.packages.length === 1)
    return (
      batch.packages[0].warehouseLocation +
      ` (${B_WAREHOUSE_OPTIONS.find((o) => o.value === batch.packages[0].warehouse)?.label ?? "Unknown"})`
    );
  const locations = batch.packages.map(
    (p) =>
      `${p.warehouseLocation} (${
        B_WAREHOUSE_OPTIONS.find((o) => o.value === batch.packages[0].warehouse)?.label ?? "Unknown"
      })`
  );
  // Remove duplicates
  const uniqueLocations = Array.from(new Set(locations));
  return uniqueLocations.join(", ");
};

/**
 * Get a location description for a batch and packages
 * @param batch a batch object
 * @returns {Array<[location: string, types: Array<[t: string, amount: number]>]>} location description for batch
 */
export const getStockLocations = (
  batch: Batch | BatchExtended | ShipmentBatch | ShipmentBatchExtended
): Array<[location: string, types: Array<[t: string, amount: number]>]> => {
  const locations: { [location: string]: { [type: string]: number } } = {};
  for (let i = 0; i < batch.packages.length; i++) {
    const p = batch.packages[i];
    const l = `${p.warehouseLocation} (${
      B_WAREHOUSE_OPTIONS.find((o) => o.value === batch.packages[0].warehouse)?.label ?? "Unknown"
    })`;
    const type = `${p.amountEach}${formatArticleUnit(batch.unit)} ${B_PACKAGE_DICT[p.packageType]}`;
    if (l in locations) {
      if (type in locations[l]) locations[l][type]++;
      else locations[l][type] = 1;
    } else {
      locations[l] = { [type]: 1 };
    }
  }

  return Object.entries(locations).map(
    (l: [location: string, types: { [type: string]: number }]) =>
      [l[0], Object.entries(l[1])] as [location: string, types: Array<[t: string, amount: number]>]
  );
};

/**
 * Get a package type description for a batch and packages
 * @param batch a batch or used batch object
 * @param excludeEmptyPackages optional, if true only packages with an amount greater than 0 will be counted
 * @returns {string} package type description for batch
 */
export const getStockPackageType = (
  batch: Batch | UsedBatch | BatchExtended,
  excludeEmptyPackages?: boolean
): string => {
  if (batch.packages.length === 1) return `1 x ${B_PACKAGE_DICT[batch.packages[0].packageType]}`;
  const packageTypes: { [packageType: string]: number } = {};
  for (let i = 0; i < batch.packages.length; i++) {
    const p = batch.packages[i];
    if (!excludeEmptyPackages || (excludeEmptyPackages && p.amountEach > 0)) {
      if (p.packageType in packageTypes) packageTypes[p.packageType]++;
      else packageTypes[p.packageType] = 1;
    }
  }
  const typeList = Object.entries(packageTypes).map((p) => `${p[1]} x ${B_PACKAGE_DICT[p[0] as PackageType]}`);
  return typeList.join(", ");
};

/**
 * Sort a list of batches according to the given sort properties
 * @param stock list of batches to sort
 * @param sortColumn sort properties with property and order to sort with
 * @returns {Array<Batch | ShipmentBatch>} sorted stock list
 */
export const sortStock = (
  stock: Array<Batch | ShipmentBatch>,
  sortColumn?: SortColumn
): Array<Batch | ShipmentBatch> => {
  if (!sortColumn) return stock;
  switch (sortColumn.column) {
    case "commodity":
      return _.orderBy(stock, "commodity.title.en", sortColumn.order);
    case "amount":
      return _.orderBy(stock, (b) => getBatchAmount(b), sortColumn.order);
    case "state":
      return _.orderBy(stock, "state", sortColumn.order);
    case "batch":
      return _.orderBy(stock, "identifier", sortColumn.order);
    case "place":
      return _.orderBy(stock, (b) => getStockLocation(b), sortColumn.order);
    case "supplier":
      return _.orderBy(stock, "supplier.name", sortColumn.order);
    case "stocked":
      return _.orderBy(stock, "stockedDate", sortColumn.order);
    case "left":
      return _.orderBy(stock, "expiry", sortColumn.order);
    default:
      return stock;
  }
};

/**
 * Get a default batch from a supplier order
 * @param supplierOrder a supplier order
 * @param shipment optional, open supplier order shipment
 * @returns {ShipmentBatch} a default batch object
 */
export function getDefaultShipmentBatch(supplierOrder: SupplierOrder, shipment: SupplierOrderShipment): ShipmentBatch {
  const defaultPackage = getDefaultBatchPackage();
  defaultPackage.amountEach = shipment.amount;
  return {
    _id: new BSON.ObjectId(),
    identifier: "",
    lot: "",
    state: B_INCOMING,
    stockedDate: new Date(),
    expiry: new Date(new Date().setFullYear(new Date().getFullYear() + 2)),
    commodity: supplierOrder.commodity,
    packages: [defaultPackage],
    notes: "",
    supplier: supplierOrder.supplier,
    customerOrders: supplierOrder ? supplierOrder.customerOrders : [],
    unit: supplierOrder.unit,
    price: supplierOrder.priceCommodities / supplierOrder.amount,
    priceCurrency: BASE_CURRENCY,
    supplierOrder: supplierOrder._id.toString(),
    timeline: [],
    disabled: false,
    shipment,
  };
}

/**
 * Filter a list of batches
 * @param stock list of batches
 * @param search search query
 * @param packageType optional, package type filter
 * @param state optional, state filter free, disabled or incoming
 * @param warehouse optional, filter for warehouse location
 * @param group optional, filter for commodity group, i.e. bio or conventional
 * @returns {Array<Batch | ShipmentBatch>} list of filtered batches
 */
export const getFilteredStock = (
  stock: Array<Batch | ShipmentBatch>,
  search: string,
  packageType?: SelectOption,
  state?: SelectOption,
  warehouse?: SelectOption,
  group?: "all" | "organic" | "conventional"
): Array<Batch | ShipmentBatch> => {
  let filteredStock = stock.slice();
  if (group) {
    if (group === "organic") filteredStock = filteredStock.filter((b) => b.commodity.organic);
    else if (group === "conventional") filteredStock = filteredStock.filter((b) => !b.commodity.organic);
  }

  if (packageType)
    filteredStock = filteredStock.filter((b) => b.packages.some((p) => p.packageType === packageType.value));
  if (warehouse) filteredStock = filteredStock.filter((b) => b.packages.some((p) => p.warehouse === warehouse.value));
  if (state) {
    switch (state.value) {
      case "free":
        filteredStock = filteredStock.filter((b) => getBatchAmount(b) > 0 && !b.disabled && !("shipment" in b));
        break;
      case "enabled":
        filteredStock = filteredStock.filter((b) => !b.disabled && getBatchAmount(b) > 0);
        break;
      case "disabled":
        filteredStock = filteredStock.filter((b) => b.disabled || getBatchAmount(b) === 0);
        break;
      case "released":
        filteredStock = filteredStock.filter((b) => b.state === B_RELEASED);
        break;
      case "blocked":
        filteredStock = filteredStock.filter((b) => b.state === B_BLOCKED);
        break;
      case "incoming":
        filteredStock = filteredStock.filter((b) => "shipment" in b || b.state === B_INCOMING);
        break;
      default:
        // TODO RB-159
        break;
    }
  }
  if (search.trim())
    filteredStock = doFuseSearch(
      filteredStock,
      search,
      [
        "commodity.title.en",
        "supplier.name",
        "lot",
        "identifier",
        "unit",
        "packages.amountEach",
        "packages.packageType",
        "packages.warehouse",
        "packages.warehouseLocation",
      ],
      {
        getFn: (obj: Batch | ShipmentBatch, path) => {
          // Allow to search for amount + unit
          return Array.isArray(path) && path.join(".") === "packages.amountEach"
            ? Fuse.config.getFn(obj, path).toString() + " " + Fuse.config.getFn(obj, "unit").toString()
            : Fuse.config.getFn(obj, path);
        },
      }
    );
  return filteredStock;
};

/**
 * Get formatted information of all packages in a batch about type, count, amount in one string
 * @param batch UsedBatch or Batch
 * @returns {string} A string with the formatted information about all the packages
 */
export function getBatchPackagingInfo(batch: UsedBatch | Batch): string {
  const differentPbs: Array<{ count: number; packageType: PackageType; amountEach: number }> = [];
  for (let i = 0; i < batch.packages.length; i++) {
    const p = batch.packages[i];
    const existing = differentPbs.find(
      (diff) => diff.packageType === p.packageType && diff.amountEach === p.amountEach
    );
    if (existing) {
      existing.count++;
    } else {
      differentPbs.push({ count: 1, packageType: p.packageType, amountEach: p.amountEach });
    }
  }
  return differentPbs
    .map((diff) => {
      return `${diff.count} ${diff.packageType} with ${diff.amountEach} ${formatArticleUnit(batch.unit)}`;
    })
    .join(", ");
}

/**
 * Get all valid supplier orders for the given commodity or finished product.
 * @param article Commodity or finished product or Snapshot whose supplier orders should be retrieved
 * @param context Contains all supplier orders
 * @returns {Array<SelectOption<SupplierOrder>>} List of non-canceled supplier orders for the commodity
 */
export function getSupplierOrdersForCommodity(
  article: Article | ArticleExtended | ArticleSnapshot | undefined,
  context: DataContextInternalType
): Array<SelectOption<SupplierOrder>> {
  if (!article) return [];
  return context.supplierOrder
    .filter((sO) => sO.state !== SO_CANCELED && sO.commodity._id.toString() === article._id.toString())
    .map((sOf) => {
      return { value: sOf._id.toString(), label: getOrderNumber(sOf), object: sOf };
    });
}

/**
 * Get all valid extended supplier orders for the given commodity or finished product.
 * @param article Commodity or finished product or Snapshot whose supplier orders should be retrieved
 * @param context Contains all supplier orders
 * @returns {Array<SelectOption<SupplierOrderExtended>>} List of non-canceled supplier orders for the commodity
 */
export function getExtendedSupplierOrdersForCommodity(
  article: Article | ArticleExtended | ArticleSnapshot | undefined,
  context: DataContextInternalType
): Array<SelectOption<SupplierOrderExtended>> {
  if (!article) return [];
  return context.supplierOrder
    .filter((sO) => sO.state !== SO_CANCELED && sO.commodity._id.toString() === article._id.toString())
    .map((sOf) => {
      const sOEx = extendSupplierOrder(sOf, context);
      return { value: sOf._id.toString(), label: getOrderNumber(sOf), object: sOEx };
    });
}

/**
 * Determines whether the given batch is a shipment batch or not.
 * @param batch Object that should be checked
 * @returns {boolean} Indicating if the batch is a shipment batch or not
 */
export function isShipmentBatch(batch: Batch | ShipmentBatch): batch is ShipmentBatch {
  return "shipment" in batch;
}

/**
 * Extend the common fields a batch or shipment batch contains
 * @param batch Object that should be extended
 * @param context Data context, needed to resolve data
 * @returns { {supplier: Supplier, supplierOrder: SupplierOrder, customerOrders: Array<CustomerOrder>, customerContracts: Array<CustomerContract>} }
 *  Resolved data for extended batch
 */
export function extendCommonBatchFields(
  batch: Batch | ShipmentBatch,
  context: DataContextInternalType
): {
  supplier: Supplier;
  supplierOrder: SupplierOrder | undefined;
  customerOrders: Array<CustomerOrder>;
  customerContracts: Array<CustomerContract>;
} {
  const supplier = getDocFromCollection(context.supplier, batch.supplier) as Supplier;
  const supplierOrder = batch.supplierOrder
    ? getDocFromCollection(context.supplierOrder, batch.supplierOrder)
    : undefined;
  const customerOrders: Array<CustomerOrder> = [];
  for (let i = 0; i < batch.customerOrders.length; i++) {
    const cO = getDocFromCollection(context.customerOrder, batch.customerOrders[i]);
    if (cO) customerOrders.push(cO);
  }
  const customerContracts: Array<CustomerContract> = [];
  if (batch.customerContracts) {
    for (let i = 0; i < batch.customerContracts.length; i++) {
      const cC = getDocFromCollection(context.customerContract, batch.customerContracts[i]);
      if (cC) customerContracts.push(cC);
    }
  }
  return { supplier, supplierOrder, customerOrders, customerContracts };
}

/**
 * Extends a batch that is in database representation by resolving foreign keys.
 * @param batch Batch that should be extended
 * @param context Data context, needed to resolve data
 * @returns {BatchExtended} Extended batch
 */
export function extendBatch(batch: Batch, context: DataContextInternalType): BatchExtended {
  return { ...batch, ...extendCommonBatchFields(batch, context) };
}

/**
 * Extends a shipment batch that is in database representation by resolving foreign keys.
 * @param batch Batch that should be extended
 * @param context Data context, needed to resolve data
 * @returns {ShipmentBatchExtended} Extended batch
 */
export function extendShipmentBatch(batch: ShipmentBatch, context: DataContextInternalType): ShipmentBatchExtended {
  return { ...batch, ...extendCommonBatchFields(batch, context) };
}
