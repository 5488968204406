import React, { PureComponent } from "react";
import { CloseButton, Modal } from "react-bootstrap";
import { BSON } from "realm-web";
import { toast } from "react-toastify";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { CC_STATE, CustomerContractExtended } from "../../../model/customerContract.types";
import { CustomerCustomerContractExtended } from "../../../model/customer/customerCustomerContract.types";
import {
  DataContextAnonymousType,
  DataContextCustomerType,
  DataContextInternalType,
  isAnonymousContext,
  isCustomerContext,
  isInternalContext,
} from "../../../context/dataContext";
import ErrorOverlayButton from "../../common/ErrorOverlayButton";
import userService from "../../../services/userService";
import { CUSTOMER, INTERNAL } from "../../../utils/userUtils";
import { Input } from "../../common/Input";
import OwnAddressSelector from "../../common/OwnAddressSelector";
import CalendarWeekSelector from "../../common/CalendarWeekSelector";
import { CO_ORDERCONFIRMATION, sendSlackNotification, validateAmount } from "../../../utils/customerOrderUtils";
import { T_WAREHOUSE } from "../../../model/customerOrder.types";
import { Textarea } from "../../common/Textarea";
import { callPushToArray, formatCurrency } from "../../../utils/baseUtils";
import { getCW } from "../../../utils/dateUtils";
import { getCustomerContractCall, placeCustomerContractCall } from "../../../utils/customerContractUtils";
import { CUSTOMERORDER } from "../../../services/dbService";
import { CO_FOOTER_HTML, createPDF } from "../../../utils/pdfUtils";
import { STANDARDTEXTSHORT } from "../../../utils/pdf/templateUtils";
import { resolveFilePath } from "../../../utils/fileUtils";
import { createOrderConfirmationHTML } from "../../../utils/pdf/orderConfirmationGenerationUtils";
import Tooltip from "../../common/Tooltip";
import { AddressSelectOption } from "../../../utils/addressUtils";
import { formatArticleUnit } from "../../../utils/productArticleUtils";

import {
  reduceCustomerContract,
  reduceCustomerCustomerContract,
  reduceCustomerOrder,
} from "../../../utils/dataTransformationUtils";
import CreateCustomerAccountModal from "../../userData/anonymous/CreateCustomerAccountModal";

interface CreateCustomerContractCallModalProps extends RouteComponentProps {
  contract: CustomerContractExtended | CustomerCustomerContractExtended;
  context: DataContextInternalType | DataContextCustomerType | DataContextAnonymousType;
  small?: boolean;
}

interface CreateCustomerContractCallModalState {
  show: boolean;
  saving: boolean;
  amount: number;
  step: number;
  reference: string;
  note: string;
  tosAgreed: boolean;
  targetDate: Date | null;
  shippingAddress?: AddressSelectOption;
}

class CreateCustomerContractCallModal extends PureComponent<
  CreateCustomerContractCallModalProps,
  CreateCustomerContractCallModalState
> {
  constructor(props: CreateCustomerContractCallModalProps) {
    super(props);
    this.state = this.getDefaultState(props);
  }

  handleShow = () => this.setState({ ...this.getDefaultState(this.props, true) });
  handleHide = () => (this.state.saving ? undefined : this.setState({ show: false }));
  handleContinue = () => {
    const { step } = this.state;
    if (step < 1) this.setState({ step: step + 1 });
  };
  handleBack = () => {
    const { step } = this.state;
    if (step > 0) this.setState({ step: step - 1 });
  };

  handleChangeDate = (date: Date) => this.setState({ targetDate: date });
  handleResetDate = () => this.setState({ targetDate: null });
  handleChangeAddress = (e: AddressSelectOption) => this.setState({ shippingAddress: e });
  handleChangeInput = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    // @ts-ignore
    this.setState({ [e.target.name]: e.target.type === "number" ? +e.target.value : e.target.value });
  };
  handleToggleToS = () => this.setState({ tosAgreed: !this.state.tosAgreed });

  handleCreateCall = async () => {
    const { context, contract, history } = this.props;
    const { amount, shippingAddress, reference, tosAgreed, targetDate, note } = this.state;
    const view = userService.getUserType() as string;
    if (!shippingAddress || !targetDate || !tosAgreed) return;
    this.setState({ saving: true });

    try {
      const customerOrder = getCustomerContractCall(
        contract,
        amount,
        reference,
        note,
        targetDate,
        shippingAddress.address
      );

      const result = await placeCustomerContractCall(
        reduceCustomerOrder(customerOrder),
        isCustomerContext(context)
          ? reduceCustomerCustomerContract(contract)
          : reduceCustomerContract(contract as CustomerContractExtended)
      );
      if (result && result.res && result.res.insertedId) {
        customerOrder.orderNo = result.orderNumber;
        const path = await createPDF(
          createOrderConfirmationHTML(customerOrder, STANDARDTEXTSHORT, context.currencies),
          "Order-Confirmation-" + result.orderNumber,
          contract.company._id.toString(),
          {
            marginLeft: "2cm",
            marginBottom: "4.2cm",
            footerHtml: CO_FOOTER_HTML,
          }
        );
        if (path) {
          window.open(resolveFilePath(path));
          await callPushToArray(CUSTOMERORDER, result.res.insertedId, "files", {
            _id: new BSON.ObjectId(),
            date: new Date(),
            path,
            type: CO_ORDERCONFIRMATION,
          });
        }
        sendSlackNotification(customerOrder, result.res.insertedId.toString(), contract.company);
        toast.success("Contract call-off successful");
        history.push((view === CUSTOMER ? "/order/" : "/customerOrder/") + result.res.insertedId.toString());
      } else {
        toast.error(`Call-off could not be created! ${result && result.error ? result.error : "Unknown error"}`);
        if (result && result.error) console.error(result.error);
      }
    } finally {
      this.setState({ saving: false });
    }
  };

  handleClickCreateCall = () => {
    const { context } = this.props;
    if (isAnonymousContext(context)) {
      (context.refMap["CreateCustomerAccountModal"] as CreateCustomerAccountModal).handleShow();
    } else this.handleShow();
  };

  getDefaultState = (props: CreateCustomerContractCallModalProps, show?: boolean) => {
    const { contract, context } = props;
    let targetDate = new Date(new Date().setDate(new Date().getDate() + 7));
    if (isInternalContext(context) && contract && contract.state === CC_STATE.ORDERED) {
      const { supplierOrder: supplierOrders } = context;
      const supplierOrder = supplierOrders.find(
        (sO) => sO.customerContracts && sO.customerContracts.some((cC) => cC === contract._id.toString())
      );
      if (supplierOrder) {
        const sODate = supplierOrder.changedETA
          ? supplierOrder.targetDate > supplierOrder.changedETA
            ? supplierOrder.targetDate
            : supplierOrder.changedETA
          : supplierOrder.targetDate;
        targetDate = new Date(new Date(sODate).setDate(sODate.getDate() + 2 * 7)); // so target date + 2 weeks for preparation
      }
    }
    return {
      step: 0,
      saving: false,
      show: show || false,
      reference: props.contract.customerReference,
      note: "",
      tosAgreed: false,
      amount: props.contract.contractInformation.minimumCallQuantity,
      targetDate,
    };
  };

  validateData = () => {
    const { contractInformation, commodity } = this.props.contract;
    const { amount, targetDate, shippingAddress, step, tosAgreed } = this.state;

    const view = userService.getUserType() as string;
    const suitableAmount = validateAmount(commodity, amount, T_WAREHOUSE);

    const errors: Array<string> = [];
    if (!shippingAddress) errors.push("Select a destination address");
    if (suitableAmount) errors.push("Amount is invalid");
    if (!amount) errors.push("Select an amount to order");
    if (
      view === CUSTOMER &&
      amount < contractInformation.minimumCallQuantity &&
      contractInformation.restAmount - amount !== 0 // To be able to call the remaining amount of the contract in that case
    )
      errors.push(
        `Amount is below minimum order quantity of ${contractInformation.minimumCallQuantity}${formatArticleUnit(
          commodity.unit
        )}`
      );
    if (amount > contractInformation.restAmount)
      errors.push(
        `Amount exceeds the remaining amount ${contractInformation.restAmount}${formatArticleUnit(commodity.unit)}`
      );
    if (
      view === CUSTOMER &&
      contractInformation.restAmount - amount < contractInformation.minimumCallQuantity &&
      contractInformation.restAmount - amount !== 0 &&
      contractInformation.restAmount / contractInformation.minimumCallQuantity >= 2 // To prevent issues when the rest is not dividable by the mcq
    )
      errors.push(
        `Rest amount would be below minimum order quantity of ${
          contractInformation.minimumCallQuantity
        }${formatArticleUnit(commodity.unit)}`
      );
    if (!targetDate) errors.push("Select a target week for the delivery");
    if (step === 1 && !tosAgreed) errors.push("Please confirm the checkbox");

    return errors;
  };

  render() {
    const { contract, context, small } = this.props;
    const { show, step, amount, targetDate, shippingAddress, reference, note, tosAgreed, saving } = this.state;
    const errors = this.validateData();
    const now = new Date();
    const view = userService.getUserType() as string;
    const { contractInformation, validityPeriod, commodity, priceInformation } = contract;
    const suitableAmount = validateAmount(commodity, amount, T_WAREHOUSE);
    const pricePerUnit =
      (priceInformation.totalPrice / contractInformation.totalAmount) * (1 - (priceInformation.discount || 0) / 100);
    const disabled =
      (view === CUSTOMER &&
        (now < validityPeriod.start || validityPeriod.end < now || contract.state !== CC_STATE.READY)) ||
      (view === INTERNAL && ![CC_STATE.ORDERED, CC_STATE.READY].includes(contract.state));
    return (
      <>
        {small ? (
          <ErrorOverlayButton
            errors={disabled ? ["Contract not ready or canceled"] : []}
            className="btn btn-light btn-sm py-1 px-3 element-show-hover"
            buttonText={
              <>
                <i className="fa fa-plus mr-1" />
                <span>Create Call</span>
              </>
            }
            onClick={this.handleClickCreateCall}
          />
        ) : (
          <li className="nav-item ms-auto">
            {disabled ? (
              <div className="btn w-100 bg-warning disabled text-center fw-bolder">
                {now < validityPeriod.start
                  ? "Contract not ready yet"
                  : validityPeriod.end < now
                  ? "Contract not valid anymore"
                  : contract.state !== CC_STATE.READY
                  ? "Call-Offs not available"
                  : ""}
              </div>
            ) : (
              <button
                className={"btn w-100 btn-success " + (disabled ? " disabled" : "")}
                disabled={disabled}
                title={disabled ? "Contract not ready yet or already closed" : undefined}
                onClick={disabled ? undefined : this.handleClickCreateCall}
              >
                Create Call-Off
              </button>
            )}
          </li>
        )}
        <Modal contentClassName="bg-dark" show={show} onHide={this.handleHide} centered size="xl">
          <Modal.Header className="border-0 pb-0">
            <Modal.Title>
              <h1 className="fw-bolder d-flex align-items-center text-white">Contract Call-Off</h1>
            </Modal.Title>
            <CloseButton variant="white" onClick={this.handleHide} />
          </Modal.Header>
          <Modal.Body>
            <div className="card border-0 bg-white">
              <div className="card-header border-0 pt-5">
                <h3 className="card-title flex-column">
                  <span className="card-label fw-bolder fs-3 mb-1">
                    {step === 0 ? "Call-Off Settings" : step === 1 ? "Overview" : ""}
                  </span>
                  <span className="text-muted fw-normal" style={{ fontSize: "13px" }}>
                    {step === 0
                      ? "Specify the desired amount, destination and other preferences"
                      : step === 1
                      ? `Review and confirm your call-off`
                      : ""}
                  </span>
                </h3>
              </div>
              <div className="card-body d-flex flex-column" style={{ minHeight: "350px" }}>
                {step === 0 ? (
                  <>
                    <div>
                      <label className="d-flex align-items-center fs-6 fw-bold mb-2">
                        <span className="required">Amount</span>{" "}
                        {amount < contractInformation.minimumCallQuantity && (
                          <Tooltip
                            tooltipText={
                              <span className="text-warning fw-bold ">
                                Current amount of {amount}
                                {formatArticleUnit(commodity.unit)} is below minimum quantity of{" "}
                                {contractInformation.minimumCallQuantity}
                                {formatArticleUnit(commodity.unit)}
                              </span>
                            }
                          >
                            <i className="fas fa-exclamation-triangle text-warning ml-1" style={{ fontSize: "13px" }} />
                          </Tooltip>
                        )}
                      </label>
                      <div className="input-group">
                        <Input
                          className="form-control custom-form-control"
                          type="number"
                          name="amount"
                          min={contractInformation.minimumCallQuantity}
                          max={contractInformation.restAmount}
                          value={amount}
                          onBlur={this.handleChangeInput}
                        />
                        <div className="input-group-append rounded-end bg-custom-light-gray">
                          <div className="form-control custom-form-control" style={{ padding: ".375rem .75rem" }}>
                            {formatArticleUnit(commodity.unit)}
                          </div>
                        </div>
                      </div>
                      <div className="text-danger float-right " style={{ height: "1rem" }}>
                        {suitableAmount
                          ? `Amount cannot be ordered. ` +
                            (suitableAmount.lower >= contractInformation.minimumCallQuantity &&
                            suitableAmount.upper <= contractInformation.restAmount
                              ? `Next possible amounts would be ${suitableAmount.lower} ${formatArticleUnit(
                                  commodity.unit
                                )} or 
                  ${suitableAmount.upper} ${formatArticleUnit(commodity.unit)}`
                              : suitableAmount.lower >= contractInformation.minimumCallQuantity
                              ? `Next suitable amount is ${suitableAmount.lower} ${formatArticleUnit(commodity.unit)}`
                              : suitableAmount.upper <= contractInformation.restAmount
                              ? `Next suitable amount is ${suitableAmount.upper} ${formatArticleUnit(commodity.unit)}`
                              : "")
                          : ""}
                      </div>
                    </div>
                    <div className="mb-4">
                      <label className="align-items-center fs-6 fw-bold mb-2">
                        <span className="required">Destination Address</span>
                      </label>
                      <OwnAddressSelector
                        context={context}
                        onChange={this.handleChangeAddress}
                        value={shippingAddress}
                        company={view === CUSTOMER ? undefined : contract.company}
                        matchFormControl={true}
                        placeholder="Shipping Address..."
                      />
                    </div>
                    <div className="mb-4">
                      <label className="required fs-5 fw-bold mb-2">Targeted Delivery</label>
                      <CalendarWeekSelector
                        value={targetDate}
                        min={new Date(new Date().setDate(now.getDate() + 7))} // 1 week
                        onSelectCalendarWeek={this.handleChangeDate}
                        onClear={this.handleResetDate}
                      />
                    </div>
                    <div className="mb-4">
                      <label className="align-items-center fs-6 fw-bold mb-2">
                        <span>Your Internal Reference</span>
                      </label>
                      <Input
                        type="text"
                        className="form-control custom-form-control"
                        placeholder="Reference like order number etc."
                        name="reference"
                        value={reference}
                        onChange={this.handleChangeInput}
                      />
                    </div>
                  </>
                ) : step === 1 ? (
                  <div className="flex-grow-1">
                    <div className="mb-4">
                      <div className="row">
                        <div className="col-3 my-auto">
                          <label className="fs-7 text-white fw-bold my-auto">Amount</label>
                        </div>
                        <div className="col">
                          <Input
                            className="form-control custom-form-control"
                            type="text"
                            name="amount"
                            value={amount + formatArticleUnit(commodity.unit)}
                            disabled={true}
                          />
                        </div>
                      </div>
                      <div className="row mt-2">
                        <div className="col-3 my-auto">
                          <label className="fs-7 text-white fw-bold my-auto">Shipping Address</label>
                        </div>
                        <div className="col">
                          <Textarea
                            className="form-control custom-form-control"
                            value={shippingAddress?.value}
                            disabled={true}
                          />
                        </div>
                      </div>
                      <div className="row mt-2">
                        <div className="col-3 my-auto">
                          <label className="fs-7 text-white fw-bold my-auto">Your Reference</label>
                        </div>
                        <div className="col">
                          <Input
                            type="text"
                            className="form-control custom-form-control"
                            name="reference"
                            value={reference || "-"}
                            disabled={true}
                          />
                        </div>
                      </div>
                      <div className="row mt-2">
                        <div className="col-3 my-auto">
                          <label className="fs-7 text-white fw-bold my-auto">Note</label>
                        </div>
                        <div className="col">
                          <Textarea
                            className="form-control custom-form-control"
                            name="note"
                            value={note}
                            placeholder="Additional notes for our team..."
                            onChange={this.handleChangeInput}
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      className="d-flex flex-column text-center mb-4 p-2 background-secondary"
                      style={{ borderRadius: 10 }}
                    >
                      <span className="text-bold text-white fs-3">
                        {formatCurrency(pricePerUnit, priceInformation.currency)}/{commodity.unit}
                      </span>
                      <span className="fw-bolder fs-1">
                        {formatCurrency(pricePerUnit * amount, priceInformation.currency)}
                      </span>
                      <span className="text-muted fw-bold mt-0 mb-2">
                        <span className={"text-success"}>{`Delivery targeted for CW ${
                          targetDate ? `${getCW(targetDate)}-${targetDate.getFullYear()}` : "-"
                        }`}</span>
                      </span>
                    </div>
                    <div className="mb-5">
                      <div className="form-check form-check-sm form-check-custom form-check-solid">
                        <input
                          className="form-check-input position-static"
                          checked={tosAgreed}
                          type="checkbox"
                          onChange={this.handleToggleToS}
                        />
                        <label className="form-check-label fs-5">
                          {view === CUSTOMER ? (
                            <span>
                              I have read and agreed the{" "}
                              <a href="/tos" target="_blank" rel="noopener noreferrer">
                                terms of service
                              </a>
                              . I understand that a placed order might not be cancelable anymore.
                            </span>
                          ) : (
                            "I guarantee that the customer has authorised me to create this order. I understand that unauthorized orders can be harmful to business and may result in disciplinary consequences."
                          )}
                        </label>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-text-white" onClick={this.handleHide}>
              Close
            </button>
            {step > 0 && (
              <button className="btn btn-light" onClick={this.handleBack}>
                Back
              </button>
            )}
            <ErrorOverlayButton
              errors={errors}
              saving={saving}
              className="btn btn-light"
              buttonText={step === 1 ? "Confirm" : "Continue"}
              onClick={step === 1 ? this.handleCreateCall : this.handleContinue}
            />
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default withRouter(CreateCustomerContractCallModal);
